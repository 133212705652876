import NanoPopup from "./NanoPopup";

/**
 * This class is a queue for popups. It will show the popups according to their priority.
 * If a popup needs to wait for a promise to be displayed, you can pass the promise to the Add method.
 */
export default class NanoPopupQueue {
  private queue: {
    popup: NanoPopup;
    priority: number;
    waitForPromise?: Promise<void>;
  }[] = [];

  constructor() {
    // Will run this function when the document is ready
    $(() => {
      if (this.queue.length === 0 && this._communicationPopup) {
        this._communicationPopup.Show();
        return;
      }
      this.ShowNext();
    });
  }

  public static get instance(): NanoPopupQueue {
    if (!globalThis.popupQueue) {
      globalThis.popupQueue = new NanoPopupQueue();
    }
    return globalThis.popupQueue;
  }

  /**
   * The communication popup is a special popup that will never be shown if another popup is queued.
   * @private
   */
  private _communicationPopup?: NanoPopup = null;

  public set communicationPopup(value: NanoPopup) {
    this._communicationPopup = value;
  }

  /**
   *
   * @param popup
   * @param priority The lower the number, the higher the priority
   * @param waitForPromise
   * @constructor
   */
  public Add(
    popup: NanoPopup,
    priority: number,
    waitForPromise: Promise<void> = null,
  ): void {
    this.queue.push({ popup, priority, waitForPromise });
  }

  public ShowNext(): void {
    if (this.queue.length === 0) {
      return;
    }
    this.queue.sort((a, b) => a.priority - b.priority);
    const next = this.queue.shift();
    if (next.waitForPromise) {
      next.waitForPromise.then(() => {
        next.popup.config.isHidden = () => {
          this.ShowNext();
        };
        next.popup.Show();
      });
      return;
    }
    next.popup.config.isHidden = () => {
      this.ShowNext();
    };
    next.popup.Show();
  }
}
