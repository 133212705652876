import MultiPanelsNanoPopup from "../../../components/Popups/MultiPanelsNanoPopup";
import "../../../../resources/less/Troubadour/Babillard/popups/ShareBabillardPopup.less";
import RestPromise from "../../../utils/RestPromise";

export default class FamilySubscriptionPopup extends MultiPanelsNanoPopup {
  private activationCodeInput: JQuery<HTMLInputElement>;
  private codeCorrect: boolean = false;

  constructor() {
    super({
      target: "#family-sub-required-popup",
      onHide: () => {
        this.showError("");
        this.activationCodeInput.val("");

        if (this.codeCorrect) {
          location.reload();
        }
      },
    });
    this.activationCodeInput = $(".activation-code");

    $(".code-button").on("click", () => {
      this.goTo(1);
    });

    $(".code-confirm-button").on("click", () => {
      this.checkCode();
    });

    this.activationCodeInput.on("change", () => {
      this.showError("");
    });
  }

  private checkCode() {
    const code = this.activationCodeInput.val();
    if (code) {
      RestPromise.Create("/rest/licence/code", "POST", { code: code })
        .then(() => {
          this.goTo(2);
          this.codeCorrect = true;
        })
        .catch((error) => {
          this.showError(error.responseJSON.message);
        });
    }
  }

  private showError(error: string) {
    $(".error-message").text(error);
  }
}
