import NanoPopup from "./NanoPopup";
import JQuery from "jquery";
import { TNanoPopupConfig } from "../types/TNanoPopupConfig";

export default class MultiPanelsNanoPopup extends NanoPopup {
  protected currentStep = 0;
  private readonly panels: JQuery<HTMLElement>;
  private stepButtons: JQuery<HTMLElement>;
  private inTransition = false;

  constructor(config: TNanoPopupConfig) {
    super(config);
    this.panels = $(this.config.target).find(".popup-section");

    //Buttons that shows a specific panel of the popup
    this.stepButtons = $(this.config.target).find(".step-button");
    this.stepButtons.on("click", (e: JQuery.ClickEvent) => {
      this.onStateChange(e);
    });

    //Go to step zero
    this.goTo(0);
  }

  public override Hide(): void {
    if (this.inTransition) {
      return;
    }
    super.Hide();
  }

  protected onStateChange(event: JQuery.ClickEvent): void {
    const { stepTo } = event.currentTarget.dataset || null;
    this.goTo(stepTo ? +stepTo : null);
  }

  protected override resetButton() {
    this.actionButton.removeClass("disabled");
    this.goTo(0);
  }

  protected goTo(step?: number): void {
    if (step === null) {
      console.warn("Step not defined");
    }
    if (this.inTransition) {
      return;
    }
    this.currentStep = step;
    if (step !== 0) {
      this.inTransition = true;
      this.stepButtons.addClass("disabled");
      // Force a small delay before closing next step. Avoid multi clics issue
      const timer = setTimeout(() => {
        this.inTransition = false;
        this.stepButtons.removeClass("disabled");
        clearTimeout(timer);
      }, 750);
    }

    this.panels.css("display", "none");
    this.panels.filter(`[data-step=${step}]`).css("display", "flex");
  }
}
