import NanoPopup from "../../components/Popups/NanoPopup";
import "../../../resources/less/Troubadour/Pathway/PaidFeatureInfoPopup.less";
import "../../../resources/less/components/Popups/SwitchToFreemiumPopup.less";
import "../../../resources/less/components/Popups/SwitchToPremiumPopup.less";
import NanoPopupQueue from "../../components/Popups/NanoPopupQueue";
import PaywallPopupFactory from "./PaywallPopupFactory";
import NanoPopupShownEvent from "../../components/Popups/Events/NanoPopupShownEvent";
import NanoPopupHiddenEvent from "../../components/Popups/Events/NanoPopupHiddenEvent";

export default class Paywall {
  private readonly userType: "FREEMIUM" | "PREMIUM";
  private paidFeatureInfoPopup: NanoPopup;
  private paidFeatureInfoPopupContainer: JQuery<HTMLElement>;
  private paidFeatureItem: JQuery<HTMLElement>;

  private constructor() {
    // If user is Freemium:
    // Attach click event to paid feature links and buttons that will
    // open a popup to inform the user that this is a paid feature
    this.userType = $("body").attr("data-license-type") as
      | "FREEMIUM"
      | "PREMIUM"
      | null;

    // The paywall is only relevant if the user is a teacher.
    if (this.userType === null) {
      return;
    }
    this.paidFeatureInfoPopupContainer = $(".js-paywall-popup");
    this.Update();

    window.addEventListener("nanopopup-shown", (ev: NanoPopupShownEvent) => {
      if (!ev.paywallPopup) {
        this.currentlyDisplayedPopup = ev.popup;
      }
    });

    window.addEventListener("nanopopup-hidden", (ev: NanoPopupHiddenEvent) => {
      if (!ev.fromPaywall && !ev.paywallPopup) {
        this.currentlyDisplayedPopup = null;
      }
    });
  }

  public static get instance(): Paywall {
    if (!globalThis.paywall) {
      globalThis.paywall = new Paywall();
      globalThis.paywall.initPopups();
    }
    return globalThis.paywall;
  }

  public static get isFreemium(): boolean {
    return Paywall.instance.userType === "FREEMIUM";
  }

  private _currentlyDisplayedPopup: NanoPopup = null;

  public get currentlyDisplayedPopup(): NanoPopup {
    return this._currentlyDisplayedPopup;
  }

  public set currentlyDisplayedPopup(value: NanoPopup) {
    if (value && value.config.isPaywall) {
      return;
    }
    this._currentlyDisplayedPopup = value;
  }

  private _switchedToFreemiumPopup: NanoPopup;

  private get switchedToFreemiumPopup(): NanoPopup {
    return this._switchedToFreemiumPopup;
  }

  private _switchedToPremiumPopup: NanoPopup;

  private get switchedToPremiumPopup(): NanoPopup {
    return this._switchedToPremiumPopup;
  }

  public Update(): void {
    this.paidFeatureItem = $("[data-paid-feature=1]");
    this.paidFeatureItem.off("click.freemium");
    if (this.userType == "FREEMIUM") {
      this.paidFeatureItem.on("click.freemium", (ev) => {
        const paidFeatureName = $(ev.currentTarget).attr("data-feature-name")
          ? $(ev.currentTarget).attr("data-feature-name")
          : "default-paid-feature";
        this.paidFeatureInfoPopupContainer.attr(
          "data-feature-name",
          paidFeatureName,
        );
        this.paidFeatureInfoPopup.Show();
      });
    }
  }

  private initPopups(): void {
    const popupFactory = new PaywallPopupFactory();
    // Paid feature information popup
    this.paidFeatureInfoPopup = popupFactory.Create();

    if ($("#switch-to-freemium-popup").length > 0) {
      this._switchedToFreemiumPopup = new NanoPopup({
        target: "#switch-to-freemium-popup",
      });
      NanoPopupQueue.instance.Add(this._switchedToFreemiumPopup, 10);
    }

    if ($("#switch-to-premium-popup").length > 0) {
      this._switchedToPremiumPopup = new NanoPopup({
        target: "#switch-to-premium-popup",
      });
      NanoPopupQueue.instance.Add(this._switchedToPremiumPopup, 10);
    }
  }
}
