import "../../../resources/less/components/Help/VideoTutorial.less";

class VideoTutorial {
  private video: JQuery<HTMLElement>;
  private triggerBtn: JQuery<HTMLElement>;
  private popup: JQuery<HTMLElement>;
  private backdrop: JQuery<HTMLElement>;
  private videoTitle: JQuery<HTMLElement>;
  private popupCloseBtn: JQuery<HTMLElement>;
  private readonly videoListButtons: JQuery<HTMLElement>;
  private videoNextNavigation: JQuery<HTMLElement>;
  private videoPreviousNavigation: JQuery<HTMLElement>;
  private selectedIndex: number;

  constructor() {
    this.triggerBtn = $(".video-tutorial-button");
    this.popup = $(".video-tutorial");
    this.backdrop = $(".video-tutorial .backdrop");
    this.videoTitle = $(".video-tutorial .video-title");

    // Navigation
    this.popupCloseBtn = $(".video-tutorial .close-button");
    this.videoListButtons = $(".video-list-button");
    this.videoPreviousNavigation = $(
      ".video-tutorial  .video-nav.back-controls",
    );
    this.videoNextNavigation = $(
      ".video-tutorial  .video-nav.forward-controls",
    );
    this.selectedIndex = 0;

    // Event listeners
    this.triggerBtn.on("click", () => {
      this.Show();
    });
    this.backdrop.on("click", () => {
      this.Hide();
    });
    this.popupCloseBtn.on("click", () => {
      this.Hide();
    });

    this.videoListButtons.on("click", (event) => {
      const { listIndex } = event.currentTarget.dataset;
      this.SelectVideoByIndex(listIndex);
    });

    this.videoPreviousNavigation.on("click", () => {
      this.SelectPreviousVideo();
    });
    this.videoNextNavigation.on("click", () => {
      this.SelectNextVideo();
    });

    if (this.HasVideosToDisplay()) {
      this.Show();
    }
  }

  HasVideosToDisplay() {
    return (
      $("#display-lexicon-on-login").length === 0 &&
      this.popup.hasClass("display-first-time")
    );
  }

  Show() {
    this.popup.removeClass("hide");
    this.popup.addClass("show");
    const player = $(".video-player");
    const { pattern, url, title } = player[0].dataset;
    player.html(pattern);
    this.video = $(".video-tutorial iframe");
    if (this.videoListButtons.length > 0) {
      this.SelectVideoByIndex(this.selectedIndex);
    } else {
      this.SwitchVideo(url, title, this.selectedIndex);
    }
  }

  Hide() {
    this.popup.addClass("hide");
    this.popup.removeClass("show");
    this.popup.removeClass("display-first-time");
    this.VideoReset();

    // Resets list to first element
    this.selectedIndex = 0;
    if (this.videoListButtons.length > 0) {
      this.SelectVideoByIndex(this.selectedIndex);
    }
  }

  SelectPreviousVideo() {
    this.selectedIndex--;

    // Loops back to last element
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.videoListButtons.length - 1;
    }

    this.SelectVideoByIndex(this.selectedIndex);
  }

  SelectNextVideo() {
    this.selectedIndex++;

    // Loops back to first element
    if (this.selectedIndex >= this.videoListButtons.length) {
      this.selectedIndex = 0;
    }

    this.SelectVideoByIndex(this.selectedIndex);
  }

  SelectVideoByIndex(index) {
    const [button] = $(this.videoListButtons[index]);
    const { url, title, listIndex } = button.dataset;
    this.SwitchVideo(url, title, listIndex);

    // Sets active button style to disabled
    this.videoListButtons.removeClass("disabled");
    $(this.videoListButtons[index]).addClass("disabled");
  }

  SwitchVideo(url, title, index) {
    this.videoTitle.text(title);
    this.video.attr("src", url);
    this.selectedIndex = index;
  }

  VideoReset() {
    this.video.attr("src", this.video.attr("src"));
  }
}

export default VideoTutorial;
